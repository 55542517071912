import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

import GodPanel from './components/panels/godPanel.js';
import UserPanel from './components/panels/userPanel.js';
import VideoPanel from './components/panels/videoPanel.js';

import logo from './images/CyasoonGodModeLogo.png';
import leftArrow from './images/arrow.left.circle.fill.png';
import rightArrow from './images/arrow.forward.circle.fill.png';

import PasswordPage from './PasswordPage';

import './App.css';
import './css/fonts.css';
import './css/main.css';

//==============================================================================

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [videos, setVideos] = useState([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedVideo, setSelectedVideo] = useState("");
  const [selectedPhoto, setSelectedPhoto] = useState("");
  const [selectedTimeslot, setSelectedTimeslot] = useState("");
  const [selectedDay, setSelectedDay] = useState("");
  const [pagination, setPagination] = useState(null); // Track the pagination object
  const godPanelRef = useRef(null); // Create a ref for GodPanel

  const pageSize = 25; // Number of videos per page

  useEffect(() => {
    const storedAuth = localStorage.getItem('isAuthenticated');

    if (storedAuth) {
      setIsAuthenticated(true);
    }

    fetchVideos(1);

  }, []); // Initial fetch

  const fetchVideos = (page, setLastVideo = false) => {
    axios.get(`${process.env.REACT_APP_CYASOON_GET_VIDEOS_BY_TAGS_ROUTE}?page=${page}&pageSize=${pageSize}`)
      .then(response => {
        const { data, pagination } = response.data;
        let videos = data.map(video => ({
          videoUrl: video.assets.mp4,
          photoUrl: video.assets.thumbnail
        }));
        setVideos(videos);
        setPagination(pagination); // Update pagination object
        setCurrentVideoIndex(setLastVideo ? videos.length - 1 : 0); // Reset or set the video index
      })
      .catch(error => {
        console.log("Error: " + error.message);
      });
  };

  const handleUserSelected = () => {
    const emailAddress = UserPanel.getEmailAddressValue();
    const objectId = UserPanel.getObjectIdValue();

    setSelectedUser(objectId);
    document.getElementById('alreadySelectedUserPanel').innerHTML = emailAddress;
  };

  const handleVideoSelected = () => {
    const videoUrl = videos[currentVideoIndex].videoUrl;
    const photoUrl = videos[currentVideoIndex].photoUrl;

    document.getElementById('smallVideoPlayer').src = videoUrl + '#autoplay;loop';
    setSelectedVideo(videoUrl);
    setSelectedPhoto(photoUrl);
  };

  const handleNextVideo = () => {
    if (currentVideoIndex < videos.length - 1) {
      setCurrentVideoIndex(currentVideoIndex + 1);
    } else if (pagination) {
      if (pagination.currentPage < pagination.pagesTotal) {
        fetchVideos(pagination.currentPage + 1);
      } else {
        fetchVideos(1); // Loop back to the first page
      }
    }
  };

  const handlePreviousVideo = () => {
    if (currentVideoIndex > 0) {
      setCurrentVideoIndex(currentVideoIndex - 1);
    } else if (pagination) {
      if (pagination.currentPage > 1) {
        fetchVideos(pagination.currentPage - 1, true);
      } else {
        fetchVideos(pagination.pagesTotal, true);
      }
    }
  };

  function transformToUTC(selectedDay, selectedTimeslot) {
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  
    // Function to get the index of the day
    const getDayIndex = (day) => daysOfWeek.indexOf(day);
  
    // Function to convert timeslot to hours
    const timeslotToHours = (timeslot) => {
      const [start, end] = timeslot.split('-').map(time => time.split(':').map(Number));
      return { start: start[0], end: end[0] };
    };
  
    // Function to format hours into a time string
    const formatTime = (hours) => {
      const h = hours % 24;
      const formattedHours = h < 10 ? `0${h}:00` : `${h}:00`;
      return formattedHours;
    };
  
    // Find the index of the selected day
    const selectedDayIndex = getDayIndex(selectedDay);
    if (selectedDayIndex === -1) {
      throw new Error('Invalid day selected');
    }
  
    // Get the hours for the selected timeslot
    const { start: startHour, end: endHour } = timeslotToHours(selectedTimeslot);
  
    // Calculate the UTC offset
    const currentDate = new Date();
    const utcOffset = currentDate.getTimezoneOffset() / 60;
  
    // Adjust the hours based on UTC offset
    let adjustedStartHour = startHour + utcOffset;
    let adjustedEndHour = endHour + utcOffset;
  
    // Adjust day if the timeslot crosses UTC day boundary
    let adjustedDayIndex = selectedDayIndex;
    if (adjustedStartHour < 0) {
      adjustedStartHour += 24;
      adjustedDayIndex = (selectedDayIndex - 1 + 7) % 7;
    } else if (adjustedStartHour >= 24) {
      adjustedStartHour -= 24;
      adjustedDayIndex = (selectedDayIndex + 1) % 7;
    }
  
    if (adjustedEndHour < 0) {
      adjustedEndHour += 24;
    } else if (adjustedEndHour >= 24) {
      adjustedEndHour -= 24;
    }
  
    // Get the adjusted day in full form
    const adjustedDay = daysOfWeek[adjustedDayIndex];
    
    // Format the adjusted timeslot
    const adjustedTimeslot = `${formatTime(adjustedStartHour)}-${formatTime(adjustedEndHour)}`;
  
    return { adjustedDay, adjustedTimeslot };
  }

  const assignGod = () => {
    var find = "/";
    var regex = new RegExp(find, "g");
    
    const selectedDataTransformUTC = transformToUTC(selectedDay, selectedTimeslot)
    var userId = selectedUser;
    var videoUrl = selectedVideo;
    var photoUrl = selectedPhoto;

    var userTZ = Intl.DateTimeFormat().resolvedOptions().timeZone
    var adjustedTimeslot = selectedDataTransformUTC.adjustedTimeslot

    if (userId === "" || videoUrl === "" || photoUrl === "" || adjustedTimeslot === "" || selectedDay === "") {
      alert("You have missing data for the submission!\nMake sure you've assigned a user, a video, AND a timeslot!");
      return;
    } else {
      console.log("Selected Timeslot: " + adjustedTimeslot);


      axios.get(process.env.REACT_APP_CYASOON_ASSIGN_GOD_MODE_ROUTE +
        '/userId=' + userId +
        '/videoUrl=' + videoUrl.replace(regex, "%2F") +
        '/photoUrl=' + photoUrl.replace(regex, "%2F") +
        '/timeSlot=' + adjustedTimeslot, {
          params: {
             userTZ,
             dayUTC: selectedDataTransformUTC.adjustedDay,
             daySelected: selectedDay
          }
        })
        .then(response => {
          let data = response.data;

          if (data.insertedId !== "") {
            alert("Success! Cyasoon Godmode status assigned!");
          } else {
            alert("Fail! Cyasoon Godmode was not assigned");
          }

          if (godPanelRef.current) {
            godPanelRef.current.refresh();
          }
        })
        .catch(error => {
          console.log("Error: " + error.message);
        });
    }
  };

  const timeslots = [
    '00:00-03:00',
    '03:00-06:00',
    '06:00-09:00',
    '09:00-12:00',
    '12:00-15:00',
    '15:00-18:00',
    '18:00-21:00',
    '21:00-00:00'
  ];

  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

  return (
    <>
      {
        isAuthenticated ? (
          <div className="app">
            <header className="appHeader">
              <img src={logo} className="appLogo" alt="logo" />
            </header>

            <div className="godUsers">
              Gods
            </div>

            <div className="godPanel">
              <GodPanel ref={godPanelRef} /> {/* Attach the ref to GodPanel */}
            </div>

            <div className="mainPanel">

              <div className="buttonPanel">
                <div className="selectUserButtonPanel">
                  <button id="selectUserButton" name="selectUserButton" onClick={handleUserSelected} className="primary-btn">Select User</button>
                </div>

                <div className="assignGodButtonPanel">
                  <button id="assignGodButton" name="assignGodButton" onClick={assignGod} className="primary-btn">Assign Cyasoon God</button>
                </div>

                <div className="selectVideoButtonPanel">
                  <img src={leftArrow} onClick={handlePreviousVideo} className="previousVideoImage" alt="Previous Video" />
                  <button id="selectVideoButton" name="selectVideoButton" onClick={handleVideoSelected} className="primary-btn">Select Video</button>
                  <img src={rightArrow} onClick={handleNextVideo} className="nextVideoImage" alt="Next Video" />
                </div>
              </div>

              <div className="assignmentPanel">
                <div className="userSelectionPanel">
                  <div className="userPanel">
                    <UserPanel />
                  </div>
                </div>

                <div className="controlPanel">
                  <div style={{ height: 20, marginTop: 10, marginBottom: 10 }}>
                    Selected Day
                  </div>
                  <select
                    id="timeslot"
                    name="timeslot"
                    className="timeslot-select"
                    value={selectedDay}
                    onChange={(e) => setSelectedDay(e.target.value)}
                  >
                    <option value="">Select Day</option>
                    {days.map((slot, index) => (
                      <option key={index} value={slot}>{slot}</option>
                    ))}
                  </select>

                  <div style={{ height: 20, marginTop: 20, marginBottom: 10 }}>
                    Selected Timeslot
                  </div>
                  <select
                    id="timeslot"
                    name="timeslot"
                    className="timeslot-select"
                    value={selectedTimeslot}
                    onChange={(e) => setSelectedTimeslot(e.target.value)}
                  >
                    <option value="">Select Timeslot</option>
                    {timeslots.map((slot, index) => (
                      <option key={index} value={slot}>{slot}</option>
                    ))}
                  </select>

                  <div style={{ height: 20, marginTop: 20, marginBottom: 10 }}>
                    Selected User
                  </div>
                  <div id="alreadySelectedUserPanel" name="alreadySelectedUserPanel" className="alreadySelectedUserPanel"></div>
                  <input type="hidden" id="selectedUser" name="selectedUser" value={selectedUser} />

                  <div style={{ height: 20, marginTop: 10, marginBottom: 10 }}>
                    Selected Video
                  </div>

                  <div id="alreadySelectedVideoPanel" name="alreadySelectedVideoPanel" className="alreadySelectedVideoPanel">
                    <div className="smallVideoContainer">
                      <video
                        id="smallVideoPlayer"
                        name="smallVideoPlayer"
                        title="embedVideo"
                        className="smallVideoPlayer"
                        width="116px"
                        height="204px"
                        controls
                        src={selectedVideo + '#autoplay;loop'}>
                      </video>
                    </div>
                  </div>
                  <input type="hidden" id="selectedVideo" name="selectedVideo" value={selectedVideo} />
                  <input type="hidden" id="selectedPhoto" name="selectedPhoto" value={selectedPhoto} />
                </div>

                <div className="videoSelectionPanel">
                  <div className="videoPanel">
                    <VideoPanel
                      videos={videos}
                      currentVideoIndex={currentVideoIndex}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <PasswordPage setIsAuthenticated={setIsAuthenticated} />
        )
      }
    </>
  );
}

//==============================================================================

export default App;