import React from 'react';
import '../../App.css';

const VideoPanel = ({ videos, currentVideoIndex }) => {
    const currentVideo = videos[currentVideoIndex] || {};

    return (
        <div className="videoContainer">
            <video 
                id="videoPlayer"
                name="videoPlayer"
                title="Latest Video" 
                className="videoPlayer"
                width="304px" 
                height="546px"
                controls
                src={currentVideo.videoUrl ? currentVideo.videoUrl + '#autoplay;loop' : ''}>
            </video>
            <input type="hidden" id="iframeVideoUrl" name="iframeVideoUrl" defaultValue={currentVideo.videoUrl} />
            <input type="hidden" id="iframePhotoUrl" name="iframePhotoUrl" defaultValue={currentVideo.photoUrl} />
        </div>
    );
};

export default VideoPanel;