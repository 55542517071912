import React, { Component } from 'react';
import axios from 'axios';
import '../../App.css';

//==================================================================================
//==================================================================================

class UserPanel extends Component {

    //==============================================================================

    constructor(props) {
        super(props);

        this.state = {
            objectIds: [],
            emailAddresses: [],
            searchQuery: ''
        };
    }

    //==============================================================================

    componentDidMount() {
        this.fetchAllUsers();
    }

    fetchAllUsers = () => {
        axios.get(process.env.REACT_APP_CYASOON_GET_ALL_USERS_ROUTE)
            .then(response => {
                let data = response.data;

                let objectIds = data.map(user => user._id);
                let emailAddresses = data.map(user => user.emailAddress);

                this.setState({ objectIds: objectIds, emailAddresses: emailAddresses });
            })
            .catch(error => {
                console.log("Error: " + error.message);
            });
    }

    //==============================================================================

    handleSearchChange = (event) => {
        const searchQuery = event.target.value;
        this.setState({ searchQuery });

        if (searchQuery) {
            axios.get(`${process.env.REACT_APP_CYASOON_SEARCH_BY_EMAIL_ROUTE}${searchQuery}`)
                .then(response => {
                    let data = response.data;
                    let objectIds = data.map(user => user._id);
                    let emailAddresses = data.map(user => user.emailAddress);

                    this.setState({ objectIds, emailAddresses });
                })
                .catch(error => {
                    console.log("Error: " + error.message);
                });
        } else {
            this.fetchAllUsers();
        }
    }

    //==============================================================================

    render() {
        const { objectIds, emailAddresses, searchQuery } = this.state;

        const emailOptions = emailAddresses.map((email, index) => (
            <option key={objectIds[index]} value={objectIds[index]}>{email}</option>
        ));

        return (
            <div className='select-container'>
                <input 
                    type="text" 
                    placeholder="Search email..." 
                    value={searchQuery} 
                    onChange={this.handleSearchChange} 
                />
                <select id="userEmailAddresses" name="userEmailAddresses" className="userEmailAddresses" size="25">
                    {emailOptions}
                </select>
            </div>
        );
    }

    //==============================================================================

    static getObjectIdValue() {
        var userEmailAddresses = document.getElementById('userEmailAddresses');

        if (userEmailAddresses.selectedIndex === -1)
            return null;

        return userEmailAddresses.options[userEmailAddresses.selectedIndex].value;
    }

    //==============================================================================

    static getEmailAddressValue() {
        var userEmailAddresses = document.getElementById('userEmailAddresses');

        if (userEmailAddresses.selectedIndex === -1)
            return null;

        return userEmailAddresses.options[userEmailAddresses.selectedIndex].text;
    }

    //==============================================================================

};

//==================================================================================
//==================================================================================

export default UserPanel;