import React, { Component } from 'react';
import axios from 'axios';
import '../../App.css';

class GodPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userIds: [],
      pitchVideoUrls: [],
      userGodmodeCount: 0,
      toggledUserIds: {},
      pitchList: []
    };
  }

  componentDidMount() {
    this.fetchUsers();
  }

  fetchUsers = () => {
    axios.get(process.env.REACT_APP_CYASOON_GET_ALL_USERS_GODMODE_ROUTE)
      .then(response => {
        const dayOrder = {
          'Monday': 0, 'Tuesday': 1, 'Wednesday': 2, 'Thursday': 3, 'Friday': 4, 'Saturday': 5, 'Sunday': 6
        };
  
        const aggregatedUsers = this.aggregateUsers(response.data);
        const aggregatedList = this.createAggregatedList(aggregatedUsers, dayOrder);
  
        aggregatedList.sort((a, b) => this.sortUsers(a, b, dayOrder));
  
        const userIds = aggregatedList.map(user => user.userId);
        const pitchVideoUrls = aggregatedList.map(user => user.pitchVideoUrl);
        const userGodmodeCount = userIds.length;
        this.setState({ userIds, pitchVideoUrls, userGodmodeCount, pitchList: aggregatedList });
      })
      .catch(error => console.error("Error fetching users: ", error.message));
  }

  aggregateUsers = (users) => {
    return users.reduce((acc, user) => {
      if (!acc[user.userId]) {
        acc[user.userId] = {
          ...user,
          pitchVideoUrl: [user.pitchVideoUrl],
          startTimeUTC: [user.startTimeUTC],
          endTimeUTC: [user.endTimeUTC],
          daySelected: [user.daySelected],
          id: [user._id]
        };
      } else {
        acc[user.userId].pitchVideoUrl.push(user.pitchVideoUrl);
        acc[user.userId].startTimeUTC.push(user.startTimeUTC);
        acc[user.userId].endTimeUTC.push(user.endTimeUTC);
        acc[user.userId].daySelected.push(user.daySelected);
        acc[user.userId].id.push(user._id);
      }
      return acc;
    }, {});
  }

  createAggregatedList = (aggregatedUsers, dayOrder) => {
    return Object.values(aggregatedUsers).map(user => {
      const combined = user.daySelected.map((day, index) => ({
        daySelected: day,
        pitchVideoUrl: user.pitchVideoUrl[index],
        startTimeUTC: user.startTimeUTC[index],
        endTimeUTC: user.endTimeUTC[index],
        id: user.id[index]
      }));
  
      combined.sort((a, b) => {
        const dayDiff = dayOrder[a.daySelected] - dayOrder[b.daySelected];
        if (dayDiff !== 0) return dayDiff;
  
        const timeA = this.getAdjustedTimeValue(a.startTimeUTC);
        const timeB = this.getAdjustedTimeValue(b.startTimeUTC);
        return timeA - timeB;
      });
  
      return {
        ...user,
        daySelected: combined.map(item => item.daySelected),
        pitchVideoUrl: combined.map(item => item.pitchVideoUrl),
        startTimeUTC: combined.map(item => item.startTimeUTC),
        endTimeUTC: combined.map(item => item.endTimeUTC),
        id: combined.map(item => item.id),
      };
    });
  }

  getAdjustedTimeValue = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    // Adjust times between 00:00 and 03:59 to be considered as late night
    if (hours >= 0 && hours < 4) {
      return (hours + 24) * 60 + minutes;
    }
    return hours * 60 + minutes;
  }

  getTimeValue = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  }

  sortUsers = (a, b, dayOrder) => {
    const getMinDay = (user) => Math.min(...user.daySelected.map(day => dayOrder[day]));
    const getDayTimes = (user, day) => user.startTimeUTC.reduce((acc, time, index) => {
      if (user.daySelected[index] === day) {
        acc.push(this.getAdjustedTimeValue(time));
      }
      return acc;
    }, []);
  
    const dayA = getMinDay(a);
    const dayB = getMinDay(b);
  
    if (dayA !== dayB) return dayA - dayB;
  
    const dayName = Object.keys(dayOrder).find(key => dayOrder[key] === dayA);
    const timesA = getDayTimes(a, dayName);
    const timesB = getDayTimes(b, dayName);
  
    return Math.min(...timesA) - Math.min(...timesB);
  }

  toggleDropdown = (userId) => {
    this.setState(prevState => ({
      toggledUserIds: {
        ...prevState.toggledUserIds,
        [userId]: !prevState.toggledUserIds[userId]
      }
    }));
  }

  deleteUser = (userId) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      axios.delete(`${process.env.REACT_APP_CYASOON_DELETE_USER_GODMODE_ROUTE}${userId}`)
        .then(response => {
          if (response.data.deletedCount > 0) {
            this.refresh();
            alert("Success! Cyasoon Godmode status removed!");
          } else {
            alert("Fail! Cyasoon Godmode status was not removed");
          }
        })
        .catch(error => console.error("Error deleting user: ", error.message));
    }
  }

  deleteVideo = (videoId) => {
    if (window.confirm("Are you sure you want to delete this video?")) {
      axios.delete(`${process.env.REACT_APP_CYASOON_DELETE_VIDEO_GODMODE_ROUTE}${videoId}`)
        .then(response => {
          if (response.data.deletedCount > 0) {
            this.refresh();
            alert("Success! Video removed!");
          } else {
            alert("Fail! Video was not removed");
          }
        })
        .catch(error => console.error("Error deleting video: ", error.message));
    }
  }

  refresh = () => {
    this.fetchUsers();
  }

  convertToLocalTime = (timeUTC) => {
    const date = new Date();
    const [hours, minutes] = timeUTC.split(":");
    date.setUTCHours(hours, minutes, 0, 0);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });
  };

  render() {
    const { pitchList, userGodmodeCount, toggledUserIds } = this.state;
    return (
      <div id="userGodmodePanel" name="userGodmodePanel" className="userGodmodePanel">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>User ID</th>
              <th>Number of Pitches</th>
            </tr>
          </thead>
          <tbody>
            {userGodmodeCount > 0 ? pitchList.map(user => (
              <React.Fragment key={user.userId}>
                <tr>
                  <td>
                    <div className='buttons-container'>
                      <button className="toggle-button" onClick={() => this.toggleDropdown(user.userId)}>
                        {toggledUserIds[user.userId] ? '-' : '+'}
                      </button>
                      <button className="toggle-button" onClick={() => this.deleteUser(user.userId)}> X </button>
                    </div>
                  </td>
                  <td>{user.userData?.emailAddress || 'No email'}</td>
                  <td>{user.userData?.phoneNumber}</td>
                  <td>{user.userId}</td>
                  <td>{user.pitchVideoUrl.length} videos</td>
                </tr>
                {toggledUserIds[user.userId] && user.pitchVideoUrl && user.pitchVideoUrl.map((url, index) => (
                  <tr key={`${user.userId}-${index}`} className="dropdown-content">
                    <td>
                      <div className="buttons-container">
                        <button className="toggle-button" onClick={() => this.deleteVideo(user.id[index])}> X </button>
                      </div>
                    </td>
                    <td colSpan="3">
                      <div className="video-link">
                        <a href={url} target="_blank" rel="noopener noreferrer">{url}</a>
                      </div>
                    </td>
                    <td>
                      <div className="video-link">
                        <a href={url} target="_blank" rel="noopener noreferrer">
                          <strong>Day:</strong> {user.daySelected[index]} | <strong>Time:</strong> {this.convertToLocalTime(user.startTimeUTC[index])} - {this.convertToLocalTime(user.endTimeUTC[index])}
                        </a>
                      </div>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            )) : (
              <tr key="-1">
                <td colSpan="5">No Cyasoon Gods Exist</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default GodPanel;