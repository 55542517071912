import React, { useState } from 'react';

const PasswordPage = ( { setIsAuthenticated } ) => {
  const [ password, setPassword ] = useState( '' );

  const handleSubmit = ( e ) => {
    e.preventDefault();

    if( password === 'H3ll0w0r!d' ) {
      setIsAuthenticated( true );
      localStorage.setItem( 'isAuthenticated', 'true' );
    } 
    else {
      alert( 'Incorrect password. Please try again.' );
    }
  };

  return (
    <div className="authenticationPanel">
        <div className="floatPanel">
        <form onSubmit={ handleSubmit }>
    
            <div className="titlePanel">
                <h4 className="titleHeading">Enter Your Password</h4>
            </div>
        
            <div className="passwordFieldPanel">
                <input
                type="password"
                id="password"
                name="password"
                placeholder="Password"
                className="passwordField"
                value={ password }
                onChange={ ( e ) => setPassword( e.target.value ) }
                required
                />
            </div>

            <div className="submitButtonPanel">
                <button type="submit" className="submitButton">
                    Submit
                </button>
            </div>
            
        </form>
        </div>
    </div>
  );
};

export default PasswordPage;